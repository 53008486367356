import { getEnv, types, Instance, ModelActions } from 'mobx-state-tree';
import { flow } from 'mobx';
import ApiUrls from "api/apiUrls";
import { IOptionsSuggestionStore } from '../../types';
import { eventBus, SearchEvents } from '../../utilities/event-bus';
import { KEYWORD_PARAMETER_KEY, SEARCH_INITIATED, SEARCH_OPEN_PARAM } from '../../consts';
import { getUrlParameters } from '../../utilities/parameters';

interface IView {
  readonly getSuggestions: string[];
  readonly getKeyword: string;
  readonly config: IOptionsSuggestionStore;
  readonly getShowLocalSuggestionList: boolean;
  readonly getShowExternalSuggestionList: boolean;
  readonly getShowResetIcon: boolean;
  readonly getShowSearchIcon: boolean;
  readonly isFieldFocusForced: boolean;
  readonly isFieldBlurForced: boolean;
  readonly getExternalListDomId: string;
  readonly onEmptySearch: () => void;
  readonly isLanguagePreselectionEnabled: boolean;
  readonly userLocale: string;
}

const AppStore = types
  .model('appStore', {
    keyword: types.optional(types.string, ''),
    suggestions: types.optional(types.array(types.string), []),
    showLocalSuggestionList: types.optional(types.boolean, false),
    showExternalSuggestionList: types.optional(types.boolean, false),
    showResetIcon: types.optional(types.boolean, false),
    showSearchIcon: types.optional(types.boolean, true),
    screenWidth: types.optional(types.number, 0),
    fieldFocused: types.optional(types.boolean, false),
    forceFieldFocused: types.optional(types.boolean, false),
    forceFieldBlurred: types.optional(types.boolean, false),
    externalListDomId: types.optional(types.string, ''),
  })
  .views(
    (self): IView => ({
      get getSuggestions(): string[] {
        return self.suggestions;
      },
      get config(): IOptionsSuggestionStore {
        return getEnv(self).config;
      },
      get getKeyword(): string {
        return self.keyword;
      },
      get getShowLocalSuggestionList(): boolean {
        return self.showLocalSuggestionList;
      },
      get getShowExternalSuggestionList(): boolean {
        return self.showExternalSuggestionList;
      },
      get getShowResetIcon(): boolean {
        return self.showResetIcon;
      },
      get getShowSearchIcon(): boolean {
        return self.showSearchIcon;
      },
      get isFieldFocusForced(): boolean {
        return self.forceFieldFocused;
      },
      get isFieldBlurForced(): boolean {
        return self.forceFieldBlurred;
      },
      get getExternalListDomId(): string {
        return self.externalListDomId;
      },
      get onEmptySearch(): () => void {
        return getEnv(self).config.onEmptySearch;
      },
      get isLanguagePreselectionEnabled(): boolean {
        return getEnv(self).config.isLanguagePreselectionEnabled === true;
      },
      get userLocale(): string {
        return getEnv(self).config.userLocale?.replaceAll('_', '-') ?? '';
      },
    })
  )
  .actions(
    (self: Instance<typeof AppStore>): ModelActions => ({
      setKeyword(keyword: string): void {
        self.keyword = keyword;
        self.updateShowResetIcon();

        if (self.keyword.length >= self.config.minimumKeywordLength) {
          self.fetchSuggestions(keyword);
        } else {
          self.setSuggestions([]);
        }
      },
      setKeywordWithoutSuggestions(keyword: string): void {
        self.keyword = keyword;
        self.updateShowResetIcon();
        self.setSuggestions([]);
      },
      setSuggestions(suggestions: string[]): void {
        self.suggestions = suggestions;
        self.updateSuggestionListVisibilities();
      },
      runSearchByKeyword(): void {
        const keyword = self.keyword ? self.keyword : '';
        self.runSearch(keyword);
      },
      runSearch(searchWord: string): void {
        const searchInitiated = getUrlParameters()[SEARCH_INITIATED] === 'true';
        try {
          if (searchWord.length > 0) {
            self.keyword = decodeURIComponent(searchWord);
            self.forceFieldBlurred = true;
            self.suggestions = [];
            const params = {
              [KEYWORD_PARAMETER_KEY]: searchWord,
              [SEARCH_OPEN_PARAM]: true,
              ...(self.isLanguagePreselectionEnabled && {
                [SEARCH_INITIATED]: true,
                ...(!searchInitiated && {locales: [self.userLocale]}),
              }),
              ...self.config.initParameters,
            };
            eventBus.dispatch(SearchEvents.runSearch, params);
            self.config.onSearchDispatch();
          } else if (self.onEmptySearch) {
            self.onEmptySearch();
          }
        } catch (e) {
          console.error('Something went wrong', e);
        }
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      fetchSuggestions: flow(function* fetchSuggestions(keyword: string): Generator<any, any, any> {
        try {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const url = `${ApiUrls.suggestions}?q=${keyword}`
          const resp: any = yield self.config.apiClient.get(url);

          if (resp?.data && Array.isArray(resp.data)) {
            self.setSuggestions(resp.data);
          } else {
            self.setSuggestions([]);
          }
        } catch (e) {
          console.error('suggestion fetching error:', e);
          self.setSuggestions([]);
        }
      }),
      updateSuggestionListVisibilities(): void {
        const showList = self.suggestions.length > 0 && self.keyword.length >= self.config.minimumKeywordLength;
        self.showLocalSuggestionList = self.fieldFocused && showList;

        // OLD if (self.screenWidth <= MOBILE_BREAKPOINT && showList) {
        //   // Hide everything else except the already existing suggestion list
        //   if (!!self.config.openExternalListHook) {
        //     self.config.openExternalListHook(self.getExternalListDomId);
        //   }
        //   self.showExternalSuggestionList = true;
        // } else {
        //   if (self.showExternalSuggestionList && !!self.config.closeExternalListHook) {
        //     self.config.closeExternalListHook();
        //   }
        //   self.showExternalSuggestionList = false;
        // }
        //
        // if (!self.showExternalSuggestionList && !self.showLocalSuggestionList) {
        //   self.suggestions = [];
        // }
      },
      updateShowResetIcon(): void {
        // OLD self.showResetIcon = self.screenWidth <= MOBILE_BREAKPOINT && self.keyword?.length > 0;
        self.showResetIcon = self.keyword?.length > 0;
      },
      setScreenWidth(screenWidth: number): void {
        self.screenWidth = screenWidth;
        self.updateSuggestionListVisibilities();
        // OLD self.showSearchIcon = self.screenWidth > MOBILE_BREAKPOINT;
        self.updateShowResetIcon();
      },
      resetKeyword(): void {
        self.setKeyword('');
      },
      setSearchFieldFocusStatus(focus: boolean): void {
        self.fieldFocused = focus;
        self.updateSuggestionListVisibilities();
        self.forceFieldFocused = false;
        self.forceFieldBlurred = false;
      },
      selectKeyword(keyword: string): void {
        self.setKeyword(keyword);
        self.forceFieldFocused = true;
      },
      forceFocus(): void {
        self.forceFieldFocused = true;
        self.forceFieldBlurred = false;
      },
      forceBlur(): void {
        self.forceFieldFocused = false;
        self.forceFieldBlurred = true;
      },
    })
  );

export default AppStore;
