export const ITEMS_ON_PAGE = 25;
export const FILTER_ITEMS_ON_PAGE = 5;

export const USER_ID = 20155;
export const TIMEZONE = 'UTC';
export const KEYWORD_PARAMETER_KEY = 'q';
export const SEARCH_OPEN_PARAM = 'search_open';
export const SEARCH_INITIATED = 'search_initiated';
export const RESULT_LIST_ID = 'search-results';
export const RESULT_ITEM_ID_PARAM = 'result-item';
export const FIRST_FILTER_ID = 'first-filter';
export const DEFAULT_DATE_FORMAT = { year: 'numeric', month: 'long', day: 'numeric' };

export const redirectUrlParamBlacklist = [
  'p_p_id',
  'p_p_lifecycle',
  'p_p_state',
  'p_p_mode',
  'p_p_resource_id',
  'p_p_cacheability',
];

export const LOGGER_NAMESPACE = 'valamis.searchui';

export const I18N = {
  NAMESPACE: 'vsearch',
  COMMON_NAMESPACE: 'common',
  DEFAULT_LANGUAGE: 'en',
  DEFAULT_LOCALE: 'en-US',
};

export const SEARCH_RESULTS_APP = {
  NAME: 'valamis.searchui.searchresults',
  SHORTNAME: 'SEARCHRESULTS',
  BASE_URL: '/search-results',
  DOM_ROOT_PREFIX: `root_SEARCH_RESULTS`,

  I18N: {
    NAMESPACE: 'vsearch',
    CUSTOM_TRANSLATIONS_PREFIX: 'vsearch',
    COMMON_NAMESPACE: I18N.COMMON_NAMESPACE,
    DEFAULT_LANGUAGE: I18N.DEFAULT_LANGUAGE,
    DEFAULT_LOCALE: I18N.DEFAULT_LOCALE,
  },
};

export const SEARCH_BAR_APP = {
  NAME: 'valamis.searchui.searchbar',
  SHORTNAME: 'SEARCHBAR',
  BASE_URL: '/search-bar',
  DOM_ROOT_PREFIX: `root_SEARCH_BAR`,

  I18N: {
    NAMESPACE: 'vsearch-topbar',
    CUSTOM_TRANSLATIONS_PREFIX: 'vsearch-topbar',
    COMMON_NAMESPACE: I18N.COMMON_NAMESPACE,
    DEFAULT_LANGUAGE: I18N.DEFAULT_LANGUAGE,
    DEFAULT_LOCALE: I18N.DEFAULT_LOCALE,
  },
};

export const TOAST_CONFIGURATION = {
  AUTO_DISMISS_TIMEOUT: 2500,
  Z_INDEX: '5103',
};

export const HEADERS = {
  X_CSRF_TOKEN: 'X-CSRF-Token',
  X_CLUSTER_ID: 'X-Cluster-ID',
  X_LIFERAY_HOST: 'X-Liferay-Host',
  X_INSTANCE_ID: 'X-Instance-Id',
  X_COMPANY_ID: 'X-Company-Id',
  X_USER_TIMEZONE: 'X-User-Timezone',
  X_USER_LOCALE: 'X-User-Locale',
  X_USER_ID: 'X-User-Id',
  COOKIE: 'Cookie',
  HOST: 'Host'
}
