import createApiClient from '@valamis/api';
import {
  IApiClientOptions,
  IAppOptions,
  IAppScope,
  ISearchBarAppOptions,
  ISearchBarStores,
  IStores
} from '../../types';
import { getLRSClient } from '../../utilities/lrs';
import { ConfigurationsStore, ErrorsStore, ResultStore } from '../../search-results/stores';
import { OldResultStore } from '../../search-results/stores/oldResultStore';
import { AppStore } from '../../search-bar/stores';
import ApiUrls from '../../api/apiUrls';

// XXX Here we provide the old stores, to preserve backwards compatibility
export const getOldStores = (appOptions: IAppOptions, apiConfig: IApiClientOptions, appScope?: IAppScope): IStores => {
  const {
    userId,
    userLocale,
    itemsOnPage,
    portletTitle,
    searchUrl,
    isBookmarksEnabled,
    isMessagingToolEnabled,
    renderUrl,
    onNavigateBack,
  } = appOptions;

  const apiClient = createApiClient(apiConfig);
  const lrsClient = getLRSClient(apiClient);

  const errorsStore = ErrorsStore.create({});

  const resultStoreState = appScope?.initialStoreState || {};
  // XXX Here we provide the old store, to preserve backwards compatibility
  const resultStore = OldResultStore.create(resultStoreState, {
    config: {
      ApiUrls,
      apiClient,
      errorsStore,
      lrsClient,
      isBookmarksEnabled,
      userLocale,
      renderUrl,
    },
  });

  const configStore = ConfigurationsStore.create(
    {},
    {
      config: {
        userId,
        userLocale,
        itemsOnPage,
        portletTitle,
        searchUrl,
        isBookmarksEnabled,
        isMessagingToolEnabled,
        onNavigateBack,
      },
    }
  );

  return {
    errorsStore,
    resultStore,
    configStore,
  };
};

export const getStores = (appOptions: IAppOptions, apiConfig: IApiClientOptions, appScope?: IAppScope): IStores => {
  const {
    userId,
    userLocale,
    itemsOnPage,
    portletTitle,
    searchUrl,
    isBookmarksEnabled,
    isMessagingToolEnabled,
    renderUrl,
    onNavigateBack,
  } = appOptions;

  const apiClient = createApiClient(apiConfig);
  const lrsClient = getLRSClient(apiClient);

  const errorsStore = ErrorsStore.create({});

  const resultStoreState = appScope?.initialStoreState || {};
  const resultStore = ResultStore.create(resultStoreState, {
    config: {
      ApiUrls,
      apiClient,
      errorsStore,
      lrsClient,
      isBookmarksEnabled,
      userLocale,
      renderUrl,
    },
  });

  const configStore = ConfigurationsStore.create(
    {},
    {
      config: {
        userId,
        userLocale,
        itemsOnPage,
        portletTitle,
        searchUrl,
        isBookmarksEnabled,
        isMessagingToolEnabled,
        onNavigateBack,
      },
    }
  );

  return {
    errorsStore,
    resultStore,
    configStore,
  };
};

export const getSearchBarStores = (
  appOptions: ISearchBarAppOptions,
  apiConfig: IApiClientOptions,
  appScope?: IAppScope
): ISearchBarStores => {
  const apiClient = createApiClient(apiConfig);

  const {
    suggestionUrl,
    minimumKeywordLength,
    openExternalListHook,
    closeExternalListHook,
    onEmptySearch,
    onSearchDispatch,
    initParameters,
    keyword,
    isLanguagePreselectionEnabled,
    userLocale,
    // eslint-disable-next-line react/destructuring-assignment
  } = appOptions;

  const storeState = appScope?.initialStoreState || {
    keyword,
  };

  const appStore = AppStore.create(storeState, {
    config: {
      apiClient,
      suggestionUrl,
      minimumKeywordLength,
      openExternalListHook,
      closeExternalListHook,
      onEmptySearch,
      onSearchDispatch,
      initParameters,
      isLanguagePreselectionEnabled,
      userLocale,
    },
  });

  return {
    appStore,
  };
};
